import { App } from 'vue'
import {
  Router,
  createRouter,
  RouteRecordRaw,
  createWebHistory,
  RouteRecordName,
} from 'vue-router'
import { pathToRegexp } from 'path-to-regexp'
import Layout from '@/components/Layout/index.vue'
import { hasPermission } from '@/utils'
import { store } from '@/store'
import { initGlobalUserInfo, injectRouteBadge } from '@/global'

// 路由列表
import HomeRoutes from './home'

// 导出路由实例
export const router: Router = createRouter({
  history: createWebHistory(import.meta.env.PROD ? '/' : ''),
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes: [],
})

// APP根路由
const AppRoute: RouteRecordRaw[] = [
  {
    path: '/',
    redirect: '/home', // 默认跳转至首页
    name: 'App',
    component: Layout,
    children: [],
  },
]

// 加载外部静态路由(无需鉴权)
const StaticRoute: RouteRecordRaw[] = [
  {
    path: '/401',
    name: '401',
    component: () => import('@/pages/401/index.vue'),
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/pages/404/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login/index.vue'),
  },
]

// 内部白名单路由(无需授权)
const WhiteRoute: RouteRecordRaw[] = [...HomeRoutes]

// 异步动态加载路由

const AsyncRoute: RouteRecordRaw[] = [
  {
    name: 'Merchants',
    path: 'Merchants',
    meta: { title: 'Merchants', permission: 'Merchants', icon: 'Refrigerator' },
    component: () => import('@/pages/Merchants/index.vue'),
    // children: [
    //   {
    //     name: 'Merchants',
    //     path: 'List',
    //     meta: {
    //       permission: 'Merchants',
    //       icon: 'Refrigerator',
    //       title: 'MerchantsList',
    //     },
    //     component: () => import('@/pages/Merchants/index.vue'),
    //   },
    //   // {
    //   //   name: 'vacation',
    //   //   path: 'vacation',
    //   //   meta: {
    //   //     permission: 'Merchants',
    //   //     icon: 'Calendar',
    //   //     title: 'Vacation',
    //   //   },
    //   //   component: () => import('@/pages/Merchants/vacation.vue'),
    //   // }
    // ]
  },
  /*{
    name: 'Payments',
    path: 'Payments',
    meta: { title: 'Payments', permission: 'Payments', icon: 'Document' },
    component: () => import('@/pages/Payments/index.vue'),
  },
  {
    name: 'Invoices',
    path: 'Invoices',
    meta: { title: 'Invoices', permission: 'Invoices', icon: 'User' },
    component: () => import('@/pages/Invoices/index.vue'),
  },
  {
    name: 'Refunds',
    path: 'Refunds',
    meta: { title: 'Refunds', permission: 'Refunds', icon: 'Files' },
    component: () => import('@/pages/Refunds/index.vue'),
  },*/
  {
    name: 'Payouts',
    path: 'Payouts',
    meta: { title: 'Payouts', permission: 'Payouts', icon: 'FolderRemove' },
    component: () => import('@/pages/Payouts/index.vue'),
  },
  {
    name: 'NewPayout',
    path: 'NewPayout',
    meta: {
      title: 'New Payout',
      permission: 'Payouts',
      icon: 'CreditCard',
      hidden: true,
    },
    component: () => import('@/pages/Payouts/create.vue'),
  },
  {
    name: 'PayoutDetail',
    path: 'PayoutDetail',
    meta: {
      title: 'Payout Detail',
      permission: 'Payouts',
      icon: 'CreditCard',
      hidden: true,
    },
    component: () => import('@/pages/Payouts/detail.vue'),
  },
  {
    name: 'BeneficiaryDetail',
    path: 'BeneficiaryDetail',
    meta: {
      title: 'Beneficiary Detail',
      permission: 'Payouts',
      icon: 'CreditCard',
      hidden: true,
    },
    component: () => import('@/pages/Payouts/detailBene.vue'),
  },
  {
    name: 'Refunds',
    path: 'Refunds',
    meta: { title: 'Refunds', permission: 'Refunds', icon: 'Files' },
    component: () => import('@/pages/Refunds/index.vue'),
  },
  {
    name: 'RefundDetail',
    path: 'RefundDetail',
    meta: {
      title: 'Refund Detail',
      permission: 'Refunds',
      icon: 'Files',
      hidden: true,
    },
    component: () => import('@/pages/Refunds/detail.vue'),
  },
  {
    name: 'Reports',
    path: 'Reports',
    meta: { title: 'Reports', permission: 'Payments', icon: 'Document' },
    component: () => import('@/pages/Reports/index.vue'),
  },
  {
    name: 'Repupload',
    path: 'Repupload',
    meta: {
      title: 'Upload New Reports',
      permission: 'Payments',
      icon: 'CreditCard',
      hidden: true,
    },
    component: () => import('@/pages/Reports/component/upload.vue'),
  },
  {
    name: 'Disputes',
    path: 'Disputes',
    meta: { title: 'Disputes', permission: 'Disputes', icon: 'CreditCard' },
    component: () => import('@/pages/Disputes/index.vue'),
  },
  {
    name: 'Disadd',
    path: 'Disadd',
    meta: {
      title: '新建争议',
      permission: 'Disputes',
      icon: 'CreditCard',
      hidden: true,
    },
    component: () => import('@/pages/Disputes/component/add.vue'),
  },
  {
    name: 'Disdetail',
    path: 'Disdetail',
    meta: {
      title: '查看证据',
      permission: 'Disputes',
      icon: 'CreditCard',
      hidden: true,
    },
    component: () => import('@/pages/Disputes/component/detail.vue'),
  },
  {
    name: 'Dist',
    path: 'Dist',
    meta: {
      title: 'ST争议',
      permission: 'Disputes',
      icon: 'CreditCard',
      hidden: true,
    },
    component: () => import('@/pages/Disputes/component/st.vue'),
  },
  {
    name: 'Detail',
    path: 'Detail',
    meta: {
      permission: 'Merchants',
      icon: 'CreditCard',
      hidden: true,
      title: 'Merchant Details',
    },
    component: () => import('@/pages/Detail/index.vue'),
  },
  {
    name: 'Operation',
    path: 'Operation',
    meta: {
      permission: 'Merchants',
      icon: 'CreditCard',
      hidden: true,
      title: 'Balance Management Records',
    },
    component: () => import('@/pages/Operation/index.vue'),
  },
  {
    name: 'Reconciliation',
    path: 'Reconciliation',
    meta: {
      permission: 'Refunds',
      icon: 'FolderRemove',
      title: 'Reconciliation',
    },
    children: [
      {
        name: 'Corridor',
        path: 'Corridor',
        meta: {
          permission: 'Refunds',
          icon: 'FolderRemove',
          title: 'Corridor',
        },
        component: () => import('@/pages/Reconciliation/Corridor/index.vue'),
      }
    ]
  },
  {
    name: 'Fees',
    path: 'Fees',
    meta: {
      permission: 'Refunds',
      icon: 'FolderRemove',
      title: 'Fees',
    },
    children: [
      {
        name: 'Fee',
        path: 'Fee',
        meta: {
          permission: 'Refunds',
          icon: 'FolderRemove',
          title: 'Fee',
          hidden: true,
        },
        component: () => import('@/pages/Fees/index.vue'),
      },
      {
        name: 'Billing',
        path: 'Billing',
        meta: {
          permission: 'Refunds',
          icon: 'FolderRemove',
          title: 'Billing Statement',
        },
        component: () => import('@/pages/Billing/index.vue'),
      },
      {
        name: 'FxBoard',
        path: 'FxBoard',
        meta: {
          permission: 'Refunds',
          icon: 'FolderRemove',
          title: 'Fx Board',
        },
        component: () => import('@/pages/FxBoard/index.vue'),
      },
      {
        name: 'Holiday',
        path: 'Holiday',
        meta: {
          permission: 'Refunds',
          icon: 'FolderRemove',
          title: 'HL Management',
        },
        component: () => import('@/pages/Holiday/index.vue'),
      },
      {
        name: 'Quotation',
        path: 'Quotation',
        meta: {
          permission: 'Refunds',
          icon: 'FolderRemove',
          title: 'Quotation',
        },
        component: () => import('@/pages/Quotation/index.vue'),
      },
      {
        name: 'QuotationDetail',
        path: 'QuotationDetail',
        meta: {
          title: 'Create New Quotation',
          permission: 'Refunds',
          icon: 'CreditCard',
          hidden: true,
        },
        component: () => import('@/pages/Quotation/detail.vue'),
      },
    ]
  },
  {
    name: 'Statistics',
    path: 'Statistics',
    meta: {
      title: 'Statistics',
      permission: '*',
      icon: 'Files',
      hidden: true
    },
    component: () => import('@/pages/Statistics/index.vue')
  },
  {
    name: 'Channel',
    path: 'Channel',
    meta: {
      title: 'Channel',
      permission: '*',
      icon: 'Files',
      hidden: true
    },
    component: () => import('@/pages/Statistics/detail.vue')
  },
]

// 注册应用路由
const setRouter = (app: App) => {
  // 静态路由，无需鉴权
  const InitializedRoutes: RouteRecordRaw[] = [...AppRoute, ...StaticRoute]
  InitializedRoutes.forEach((route) => router.addRoute(route))
  WhiteRoute.forEach((route) => router.addRoute('App', route))

  store.commit('addMenu', WhiteRoute) // Asynchronous Route To Menu

  // 注册动态路由(需经过授权)
  router.beforeEach(async (to) => {
    const { name } = to
    const menus = store.getters.getMenu
   
    // 校验是否白名单路由
    if (router.hasRoute(name as RouteRecordName)) {
      const hasWhiteRoute = WhiteRoute.find((r) => r.name === name)
      if (hasWhiteRoute && menus.length <= WhiteRoute.length) {
        await addAuthorizedRoute() // 首次加载动态路由
      }
      return true
    }

    // 开始注册动态路由
    return await new Promise(async (resolve) => {
      if (menus.length <= WhiteRoute.length) {
        await addAuthorizedRoute()
      }

      // 检测是否有效路由
      const isValid = router
        .getRoutes()
        .find((r) => !!pathToRegexp(r.path).exec(to.path))
      resolve(isValid ? to.fullPath : '/login') // 无效路由自动跳转首页
    })
  })
  app.use(router)
}

// 获取鉴权通过路由地址
export const addAuthorizedRoute = async () => {
  const hasLogin = store.state.user.hasLogin
  if (!hasLogin) {
    await Promise.all([
      store.dispatch('getUserInfo'),
      // 这2个方法先隐藏，如果有问题再启用，耗时太久
      // store.dispatch('getAllMerchantList'),
      // store.dispatch('getAllAccountList')
    ])
    // setTimeout(()=>{
    //   console.log(33333)
    //   store.dispatch('getAllMerchantList')
    // }, 2000)
  }
  // await store.dispatch('getUserInfo')

  // 递归校验路由鉴权，过滤无鉴权路由
  const nestedRouteAuth = (routes: RouteRecordRaw[]) =>
    routes.filter((r) => {
      const permission = (r.meta ? r.meta.permission : '') as string | string[]
      if (hasPermission(permission)) {
        if (r.children) {
          r.children = nestedRouteAuth(r.children)
        }
        return true
      }
    })
  console.time('mbo')
  const AuthorizedRoute = nestedRouteAuth(AsyncRoute)
  store.commit('addMenu', AuthorizedRoute) // Asynchronous Route To Menu
  AuthorizedRoute.forEach((route) => router.addRoute('App', route))
  console.timeEnd('mbo')
}

export default setRouter
