<template>
    <el-dropdown placement="bottom-end">
        <div class="box-l pointer">
            <span class="user-name">{{ pageData.username }}</span>
            <el-icon style="color: #fff; margin-left: 6px">
                <ArrowDownBold />
            </el-icon>
        </div>
        <template #dropdown>
            <el-dropdown-menu>
                <el-dropdown-item>
                    <el-button type="text" @click="signOut"
                        >Sign out</el-button
                    >
                </el-dropdown-item>
            </el-dropdown-menu>
        </template>
    </el-dropdown>
</template>

<script setup>
import { useRouter } from "vue-router"
import { useStore } from "vuex"
const store = useStore()
const router = useRouter()
const pageData = reactive({
	username: store.state.user.userInfo.name
})

const signOut = () => {
    localStorage.removeItem("userid")
    localStorage.removeItem("Remitter")
    localStorage.removeItem("Chamberlain")
    localStorage.removeItem("token_st_mbo")
    localStorage.removeItem("reference")
    localStorage.removeItem("amount")
    router.push({ name: "login" })
};
</script>

<style lang="scss" scoped>
.user-name {
    font-family: Arial;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #fff;
}
</style>
