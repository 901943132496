import { store } from '@/store'

/* 权限校验 */
// permissions是后端传过来的权限，
export const hasPermission = (permission: string | string[]) => {
  const permissions = store.state.user.userInfo.permissions
  // console.log(permissions)
  const check = (p: string) => permissions.indexOf(p) > -1
  if (permission === '*') return true
  return Array.isArray(permission)
    ? permission.some((per) => check(per))
    : check(permission)
}
