import axios, { Axios } from 'axios'
import { ElMessage } from 'element-plus'
// 有token健全的接口请求
console.log(import.meta.env.VITE_APP_BASE_API);
const loginapi: Axios = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_API,
  timeout: 25000, // Request Timing
  withCredentials: true, // Support Cors Cookie
})

// response拦截器
loginapi.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res instanceof Blob) {
      return res
    }
    //  console.log(res);

    if (res.code && res.code != 200) {
      // 登录失效
      if (res.code == 401) {
        const url = location.href
        // location.href = `/login?redirect=${url}`
      }
      // 接口异常
      ElMessage({
        message: res.message,
        type: 'error',
        duration: 3 * 1000,
      })
      // return Promise.reject(res.errMessage)
    }

    return res
  },
  (error) => {
    // console.log(error,'lys0718');
    const res = error.response && error.response.data
    if (res && !res.success) {
      ElMessage({
        message: res.errMessage || error.message,
        type: 'error',
        duration: 5 * 1000,
      })
    } else {
      ElMessage({
        message: error.message,
        type: 'error',
        duration: 5 * 1000,
      })
    }
    return Promise.reject(error)
  }
)
//请求拦截器
loginapi.interceptors.request.use((res: any) => {
  return res
})
export default loginapi as any
