import { Module } from 'vuex'
import {
  getGlobalUserInfo,
  AllMerchantList,
  AllAccountList
} from '@/api/index'

export interface Merchant {
  merchantId: string
  merchantName: string
}

export interface Account {
  acctId: string
  acctName: string
}

export interface User {
  hasLogin: boolean
  userInfo: {
    name: string
    id: string
    phone?: string
    avatar: string
    userId: Number
    permissions?: string[]
    unreadNotice?: Number
  },
  merchantList: Merchant[]
  accountList: Account[]
}

const user: Module<User, any> = {
  state: {
    hasLogin: false,
    userInfo: {
      name: '',
      id: '',
      phone: '',
      avatar: '',
      userId: 0,
      permissions: [],
      unreadNotice: 0,
    },
    merchantList: [],
    accountList: [],
  },
  getters: {
    getUserInfo: (state) => state,
    getPermission: (state) => state.userInfo.permissions,
  },
  mutations: {
    setPermission(state, permissions) {
      state.userInfo.permissions = permissions
    },
    setUserInfo(state, info) {
      state.hasLogin = true
      state.userInfo.name = info.userName
      state.userInfo.id = info?.id
      state.userInfo.phone = info?.phone
      state.userInfo.avatar = info?.avatar
      state.userInfo.userId = info?.userId
      state.userInfo.unreadNotice = info.unreadNotice
      state.userInfo.permissions = info.permissions
    },
    setMerchantList(state, data) {
      state.merchantList = data
    },
    setAccountList(state, data) {
      state.accountList = data
    }
  },
  actions: {
    async getUserInfo({ commit }) {
      const res = await getGlobalUserInfo()
      // console.log(res)
      if (res.code == 200) {
        commit('setUserInfo', res.user)
        commit('setPermission', res.permissions)
      }
    },
    getAllMerchantList({ commit }) {
      const res = AllMerchantList().then(({code, data})=>{
        if(code === 200){
          const data = res?.data?.map(v => ({
            merchantId: v.mid,
            merchantName: v.businessLegalName
          }))
          commit('setMerchantList', data)
        }
      })
      // console.log(res,'getAllMerchantList')
      // if (res?.code == 200) {
      //   const data = res?.data?.map(v => ({
      //     merchantId: v.mid,
      //     merchantName: v.businessLegalName
      //   }))
      //   commit('setMerchantList', data)
      // }
    },
    async getAllAccountList({ commit }) {
      const res = await AllAccountList()
      if (res?.code == 200) {
        const data = res?.data?.map(v => ({
          acctId: v.acctId,
          acctName: v.acctName
        }))
        commit('setAccountList', data)
      }
    }
  },
}

export default user
