export * from './permission'

export * from './download'

export const genGuid = () => {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`
}
// 传入二进制流来进行excel导出,如果不是二进制流，在接口处转换，然后接口转换 responseType: 'blob',把接收到的type值穿过来type
export const downloadExcelByGet = (data: ArrayBuffer, name = '表格',type='application/vnd.ms-excel') => {
  const blob = new Blob([data], { type: type })
  const href = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.style.display = 'none'
  a.href = href
  a.download = name
  a.click()
  URL.revokeObjectURL(a.href) //释放URL对象
}
export const Dates = function (time, cFormat = '') {
  if (arguments.length === 0) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if (('' + time).length === 10) time = parseInt(time) * 1000
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  }
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    if (key === 'a') {
      return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return timeStr
}
// 随机数前缀，加在文件名前，避免上传至阿里云文件名重复
export const RandomPrefix = (len: number = 12) => {
  var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz'
  var maxPos = chars.length
  var pwd = ''
  for (let i = 0; i < len; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos))
  }
  return pwd + (+new Date())
}
export const cloneDeep = (data) => {
  let result = Array.isArray(data) ? [] : {}
  for(let key in data) {
    if(data.hasOwnProperty(key)) {
      if(typeof data[key] === 'object' && data[key] !== null) {
        result[key] = cloneDeep(data[key])
      } else {
        result[key] = data[key]
      }
    }
  }
  return result
}
