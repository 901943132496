import axios, { Axios } from "axios";
import { ElMessage } from "element-plus";
// 有token健全的接口请求
const network: Axios = axios.create({
    baseURL: import.meta.env.VITE_APP_BASE_API,
    timeout: 1000000, // Request Timing
    withCredentials: true, // Support Cors Cookie
});

// response拦截器
network.interceptors.response.use((response) => {
    const res = response.data;
    if (res instanceof Blob) {
        return response;
    }
    if (res.code && res.code != 200) {
        // 登录失效
        if (res.code == 401) {
            const url = location.href;
            location.href = `/login?redirect=${url}`;
        }
        // code999
        ElMessage({
            message: res.code === 99999 ? '服务器繁忙,请联系管理员' : res.message,
            type: "error",
            duration: 3 * 1000,
        });
    }
    return res;
}, (error) => {
    const res = error.response && error.response.data;
    if (res && !res.success) {
        ElMessage({
            message: res.errMessage || error.message,
            type: "error",
            duration: 5 * 1000,
        });
    } else {
        ElMessage({
            message: error.message,
            type: "error",
            duration: 5 * 1000,
        });
    }
    return Promise.reject(error);
});
//请求拦截器
network.interceptors.request.use((res: any) => {
    if (!!localStorage.getItem("token_st_mbo")) {
        let mbo_token = "Bearer " + localStorage.getItem("token_st_mbo");
        res.headers.common["Authorization"] = mbo_token;
    }
    return res;
});
export default network as any;
