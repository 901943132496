import { App } from 'vue'
import ElementPlus from 'element-plus'
// import zhCn from 'element-plus/es/locale/lang/en'
import zhCn from 'element-plus/es/locale/lang/zh-cn'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import './scss/index.scss'

/*
 * 重写Element-UI样式，功能覆盖
 */
const setElementPlus = (app: App) => {
  // app.use(ElementPlus, { size: 'small', locale: zhCn })
  app.use(ElementPlus, { size: 'small' })
  dayjs.locale('zh-cn')
  // @ts-ignore
  dayjs.locale({ weekStart: 1 }) // 设置Element Date
}

export default setElementPlus
